<template>
  <div>
    <OnboardingLayout>
      <div class="flex flex-col">
        <div
          class="flex flex-row gap-2 cursor-pointer text-bb-brand-purple"
          @click="goBack"
        >
          <ic-chevron
            :size="16"
            class="my-auto"
          />
          <p class="p1">Back to sign in</p>
        </div>
        <div class="flex flex-col gap-4 max-w-2xl">
          <p class="h2 font-black">Limited use policy</p>
          <p class="p1">
            BrightBid use and transfer of information received from Google APIs to any other app will adhere to
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy"
              target="_blank"
              >Google API Services User Data Policy, including the Limited Use requirements</a
            >
          </p>
          <p class="p1">
            BrightBid prioritises the protection of your data in accordance with Google's OAuth API Scopes. Our access
            requests align strictly with Google's guidelines for permissible application types. Any data collected is
            utilised solely to enhance your user experience and refine our features.
          </p>

          <p class="p1">
            Your data remains under your control at all times. We do not transfer data without your explicit consent,
            except in cases required for security measures, legal obligations, or during company transitions, with your
            prior approval.
          </p>
          <p class="p1">
            Human access to your data is limited to instances essential for security protocols, legal compliance, or
            with your explicit permission. BrightBid never engages in the sale or misuse of your data for advertising,
            credit assessment, or lending purposes. Our team upholds these principles diligently.
          </p>
          <p class="p1">
            We are dedicated to maintaining robust security measures, including regular security assessments. Any
            breaches of these standards may result in Google revoking our API access. Your data security remains our top
            priority throughout all operations.
          </p>
          <p class="h4 font-black">Limited use policy</p>
          <p class="p1">
            In order to facilitate our services, we are required to share specific data with third-party tools. This
            data includes:
          </p>
          <ul class="list-decimal list-inside space-y-2 ml-5">
            <li class="p1">
              Non-personal data from Google Ads/Bing and Google Analytics, detailing impressions, clicks, and
              conversions.
            </li>
            <li class="p1">
              Segmentation data from Google, which refers to aggregated segments rather than individual data, related to
              device usage, day of the week, age, gender, and income range, already available in Google Ads.
            </li>
            <li class="p1">
              Company-specific information, inputted by the customer company, such as the business description,
              operating countries, and advertising languages.
            </li>
          </ul>
        </div>
      </div>
    </OnboardingLayout>
  </div>
</template>

<script>
import OnboardingLayout from '@/layouts/onboarding'
import IcChevron from '@/components/icon/ic-chevron'
export default {
  name: 'LimitedUsePolicy',
  components: {
    OnboardingLayout,
    IcChevron,
  },
  data() {
    return {}
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>
