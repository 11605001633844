<template>
  <div>
    <OnboardingLayout>
      <div>
        <div
          class="flex flex-row gap-2 cursor-pointer text-bb-brand-purple"
          @click="goBack"
        >
          <ic-chevron
            :size="16"
            class="my-auto"
          />
          <p class="p1">Back to sign in</p>
        </div>
        <div class="flex flex-col w-full overflow-y-auto doc-height">
          <div class="flex flex-col gap-4 max-w-2xl">
            <p class="h2 font-black">Privacy Policy</p>
            <ul class="list-decimal list-inside space-y-2 ml-5">
              <li class="h4 font-black">General</li>
              <p>
                This privacy policy describes how BrightBid AB, org. no. 559250-7957, Kungsgatan 8, 111 43 Stockholm,
                e-mail: gustav@brightbid.com, collects, uses, discloses and stores your personal information.
                <br /><br />
                The privacy policy applies when BrightBid AB enters into an agreement with a customer who purchases
                services from BrightBid AB. The privacy policy also applies to visitors to BrightBid AB’s website. With
                this Privacy Policy, we want to show how we ensure that your personal data is processed in accordance
                with current personal data legislation.
              </p>
              <li class="h4 font-black">Personal data responsibility</li>
              <ul class="space-y-2">
                <li class="h5">2.1 BrightBid AB as Personal Data Manager</li>
                <p>
                  BrightBid AB is responsible for the processing of your personal data as a customer or visitor to our
                  website and is responsible for such processing taking place in accordance with applicable legislation.
                </p>
                <li class="h5">2.2 BrightBid AB as Personal Data Assistant</li>
                <p>
                  BrightBid AB’s customers are responsible for personal data they collect from their customers. If this
                  personal data is shared with BrightBid AB, BrightBid AB acts as personal data assistant. This happens
                  e.g. when BrightBid AB gains access to personal data in e-commerce systems and databases.
                </p>
              </ul>
              <li class="h4 font-black">When do we process your personal data?</li>
              <p>
                In order for you to visit our website and have the best possible experience, receive emails or enter
                into agreements with us, we must collect and process personal information about you.
                <br />
                BrightBid AB collects and processes personal information about you when you visit our website, enter
                into agreements with us, or when you contact us by phone, e-mail or contact form. <br />
                The information collected from you when purchasing our services, or when requesting information about
                our services, is required for you to be able to enter into an agreement with BrightBid AB and for
                BrightBid AB to be able to provide its services.
              </p>
              <li class="h4 font-black">What personal data do we process about you?</li>
              <ul class="space-y-2">
                <li class="h5">4.1 For you who are a customer of BrightBid AB</li>
                <p class="gap-4">
                  The personal information BrightBid AB collects and processes about you as a customer who purchases our
                  services is: <br />
                  Name, Organization number, Address & Invoice details, Telephone number, E-mail address.
                </p>
                <li class="h5">4.2 For you who are a visitor to our website</li>
                <p>
                  The personal information BrightBid AB may collect and process about you as a visitor to our website
                  is:
                  <br />
                  Name Telephone number and / or e-mail address IP address and information about your use of BrightBid
                  AB’s website via anonymised collection for analysis tools. <br />
                  We use Hotjar, an analysis tool service from Hotjar Ltd, to collect information on how visitors use
                  our website. Hotjar collects data on visitor behavior and interactions on our website, including mouse
                  movements, clicks, scrolling, and screen activity. The information collected is used to improve the
                  user experience on our website and to optimize our website design. Hotjar uses cookies and other
                  tracking technologies to collect this data. By using our website, you consent to our use of Hotjar and
                  cookies. You can choose to block cookies by changing the settings in your browser.
                </p>
              </ul>
              <li class="h4 font-black">Why do we process information about you?</li>
              <ul class="space-y-2">
                <li class="h5">5.1 For you who are a customer of BrightBid AB</li>
                <p>
                  BrightBid AB processes your personal data for various purposes. BrightBid AB mainly processes your
                  personal data in order to:
                  <br />
                  Fulfill our obligations to you as a customer, such as the implementation of agreements between the
                  parties, invoicing and provision of our services and support Enable general customer care and customer
                  service, such as to answer questions and correct incorrect information Provide information and direct
                  marketing, by mail, email , SMS / MMS and telephone regarding BrightBid AB’s and selected partners’
                  services Provide you with relevant information and customized offers in newsletters and on the web or
                  social media Improve our customer offering, for example development of services, products and
                  functions.
                </p>
              </ul>
              <li class="h4 font-black">The legal grounds for our processing of your personal data</li>
              <p>
                BrightBid AB bases the processing of your personal data on a number of legal grounds. These are
                described in this section. <br />
                Among other things, we process your personal data in order to fulfill the agreement with you as a
                customer, such as being able to contact you for a review of our services we perform on behalf of you.
                Towards you as a visitor to our website, such as your behavior on our website, your interaction with
                BrightBid AB and your interest in our offers and products in order to fulfill our commitment to provide
                you with personal offers.<br />
                In some cases, BrightBid AB may have a legal obligation to process your personal data. This applies, for
                example, to the processing of personal data that we perform in order to meet the requirements of the
                Accounting Act.
              </p>
              <li class="h4 font-black">Compilation of our personal data processing</li>
              <p>
                In order to deliver, administer and develop our services and provide support, we have as a legal basis
                the fulfillment of purchase agreements (terms of sale). Here are categories of personal information
                name, phone number and email address. The storage period is valid for two years after you were last an
                active customer of BrightBid AB. <br />
                In order to ensure that legal requirements are complied with, such as the Accounting Act, BrightBid AB’s
                legal basis is a legal obligation. Categories of personal data are name and organization number and
                address (including billing address). We store the information as long as we are obliged to store the
                information in accordance with applicable law.
              </p>
              <li class="h4 font-black">How long do we store information about you?</li>
              <p>
                Your personal information is stored only as long as there is a need to save it for the purposes for
                which the information was collected in accordance with this Privacy Policy. BrightBid AB may save the
                information longer if necessary to comply with legal requirements or to safeguard BrightBid AB’s legal
                interests, for example if a legal process is in progress.<br />
                BrightBid AB saves personal information about customers for a maximum of 24 months since you were last
                an active customer of BrightBid AB.<br />
                BrightBid AB saves information about visitors to BrightBid AB’s website until you request that your
                personal information be deleted.
              </p>
              <li class="h4 font-black">To whom do we disclose personal information?</li>
              <p>
                BrightBid AB will disclose your information to third parties, such as to BrightBid AB’s marketing
                partners and providers of credit information services.<br />
                Third parties to whom BrightBid AB discloses information or otherwise provides information about a
                customer or visitor to BrightBid AB’s website may only use the information for the purpose of selling
                and marketing BrightBid AB’s services and products or for the purpose of delivering services related to
                BrightBid AB’s agreement with you as a customer or visitor to our website.<br />
                Personal data will be disclosed by BrightBid AB if it is necessary to comply with applicable legal
                requirements or requirements from authorities, to safeguard BrightBid AB’s legal interests or to detect,
                prevent or draw attention to fraud and other security or technical problems. <br />
                BrightBid AB will transfer your personal data to countries outside the EU / EEA, if any of BrightBid
                AB’s suppliers or partners are there. If personal data is transferred to any country outside the EU /
                EEA, BrightBid AB will take measures to ensure that the personal data remains protected and also take
                the necessary measures to legally transfer personal data to countries outside the EU / EEA.<br />
                BrightBid AB will not sell your personal information to third parties unless we have your permission to
                do so.
              </p>
              <li class="h4 font-black">Change of privacy policy</li>
              <p>
                BrightBid AB has the right to change the Privacy Policy and we will notify you when reasonable changes
                occur.
              </p>
              <li class="h4 font-black">The protection of your personal data</li>
              <p>
                We want you to be able to feel safe when you submit your personal information to us. We have developed
                and implemented the necessary security measures to protect your personal data against unauthorized
                access, alteration and deletion.
              </p>
              <li class="h4 font-black">Your rights</li>
              <p>
                BrightBid AB is responsible for your personal data being processed in accordance with current
                legislation.
                <br />
                BrightBid AB will, at your request or on its own initiative, correct, deidentify, delete or supplement
                information that is found to be incorrect, incomplete or misleading.
                <br />
                <span class="font-bold"> <p class="my-2">You have the right to request:</p></span>
                Access to your personal information. This means that you have the right to request an extract from the
                register of the processing we carry out regarding your personal data. You also have the right to receive
                a copy of the personal data processed. You have the right to receive a free extract from the register of
                which personal data is registered about you, the purposes of the processing and to which recipients the
                data has been or will be disclosed. You also have the right to receive information in the register
                extract about where the information has been obtained from if the personal information has not been
                collected from you. You also have the right to receive information about your other rights stated in
                this section in the register extract. Correction of your personal data. We will, at your request,
                correct the incorrect or incomplete information we process about you as soon as possible. Deletion of
                your personal data. This means that you have the right to request that your personal data be deleted if
                it is no longer necessary for the purpose for which it was collected. However, there may be legal
                requirements that we may not immediately delete your personal data in, for example, accounting and tax
                legislation. We will then end the processing that is done for purposes other than complying with the
                law. Limitation of treatment. This means that your personal data is marked so that it may only be
                processed for certain limited purposes. Among other things, you can request a restriction when you
                believe that your information is incorrect and you have requested correction. While the accuracy of the
                data is being investigated, their processing will be limited.
                <br />
                You have the right to data portability. This means a right to, under certain conditions, obtain and
                transfer your personal data in a structured, generally used and machine-readable format to another
                personal data controller.
                <br />
                If you do not want us to process your personal data for direct marketing, you always have the right to
                object to such processing by sending an email to gustav@brightbid.se Once we have received your
                objection, we will stop processing the personal data for such marketing purposes.
                <br />
                You have the right to submit any complaints regarding the processing of your personal data to the Data
                Inspectorate.
              </p>
              <li class="h4 font-black">Cookies</li>
              <p>
                When you visit our website, we use cookies, which you will find more information about in BrightBid AB’s
                cookie policy at the bottom of this page.
              </p>
              <li class="h4 font-black">Contact information</li>
              <p>
                Do not hesitate to contact us if you have any questions about this Privacy Policy, the processing of
                your personal data or if you want to request an extract from the register. Our contact information can
                be found below.
                <br />
                BrightBid AB Kungsgatan 8 111 43 Stockholm gustav@brightbid.com
              </p>
            </ul>
          </div>
        </div>
      </div>
    </OnboardingLayout>
  </div>
</template>

<script>
import OnboardingLayout from '@/layouts/onboarding'
import IcChevron from '@/components/icon/ic-chevron'
export default {
  name: 'PrivacyPolicy',
  components: {
    OnboardingLayout,
    IcChevron,
  },
  data() {
    return {}
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>
<style>
/* Add style here */
.doc-height {
  height: calc(100vh - 100px);
}
</style>
